<template>
  <div>
    <Loader />
    <div :style="{ display: 'flex', justifyContent: 'center' }">
    <Banner />
    <MySidebarStyle />
    </div>
    <div class="wrapper">
      <div class="content-page" id="content-page">
        <transition name="router-anim" enter-active-class="animated  fadeInUp" mode="out-in"
        leave-active-class="animated fadeOut" >
        <div :style="{ display: 'flex', justifyContent: 'center' }">
              <router-view />
          </div>
            </transition>
        </div>
     </div>
  <!-- <LayoutFooter /> -->
</div>
</template>
<script>
// import HeaderStyle from '../components/core/partials/HeaderStyle/MyHeaderStyle.vue'
// import LayoutFooter from './Components/LayoutFooter.vue'
import MySidebarStyle from '../components/core/partials/SidebarStyle/MySidebarStyle1.vue'
import Loader from '../components/core/loader/Loader'
import Banner from '../page/Home/Header.vue'
export default {
  name: 'CommonLayout',
  components: {
    Loader,
    Banner,
    MySidebarStyle
    // HeaderStyle,
    // LayoutFooter
  },
  mounted () {
    // document.body.classList = 'top-tab-horizontal'
    // document.body.classList = 'two-sidebar'
  },
  destroyed () {
    document.body.classList = ''
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
